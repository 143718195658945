
import Emitter from '@/utils/emitter'
import { Component } from 'vue-property-decorator'
import QRCode from 'qrcode'

@Component({
	data() {
		return {}
	}
})
export default class Profile extends Emitter {
	private user = {}
	created() {
		this.getUserInfo()
	}

	async getUserInfo() {
		const { data } = await this.$axios.get('/v1/jobline/auth2/user')
		const qrcode = await this.getStringToQrcode(data.code)
		this.user = {
			qrcode,
			...data
		}
	}

	async getStringToQrcode(str: string) {
		return await QRCode.toDataURL(str, {
			color: {
				dark: '#409eff'
			}
		}).then((url: any) => url)
	}
}
