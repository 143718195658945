var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("z-page", { staticClass: "container" }, [
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "descriptions" },
        [
          _c(
            "el-descriptions",
            { attrs: { title: "", column: 1, border: "" } },
            [
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v(" 用户名 ")]),
                  _vm._v(" " + _vm._s(_vm.user.username) + " "),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v(" 姓名 ")]),
                  _vm._v(" " + _vm._s(_vm.user.name) + " "),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v(" 手机号 ")]),
                  _vm._v(" " + _vm._s(_vm.user.mobile || "-") + " "),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v(" 邮箱 ")]),
                  _vm._v(" " + _vm._s(_vm.user.email || "-") + " "),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v(" 二维码 ")]),
                  _c("img", { attrs: { src: _vm.user.qrcode } }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }